<template>
  <div class="space">
    
    <!-- FACULTY -->
    <div>

      <v-card  class="mx-auto mt-5 info-card" color="transparent" dark elevation="24">
      
        <!--<v-img class="white--text" height="50" src="../assets/fondo9.svg">-->

          <h1 class="contenido-team"> FACULTY </h1>

        <!--</v-img>-->
      </v-card>

      <v-row class="mt-16 mb-16 contenido-team">

        <v-col
          align-self="center"
          v-for="(integrante,i) in faculty"
          :key="i"
          cols="12"
          sm="4"
        >
          <v-hover>
            <template> <!-- v-slot:default="{ hover }"  (para vista de cada uno) -->
              <v-card
                elevation="24"
                class="mx-auto info-card2"
                max-width="300"
                color="#f6f4f5"
              >
                <v-avatar
                  class="profile"
                  size="300"
                  rounded
                >
                  <v-img 
                    :src="integrante.img"
                    transition="fade-transition"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <v-card-text>
                  <h2 class="text-h6 primary--text">
                  {{integrante.nombre}}
                  </h2>
                  <h3>
                  {{integrante.grado}}
                  </h3>
                  {{integrante.cargo}}

                  <h4>
                  {{integrante.email}}
                  </h4>

                </v-card-text>

                <!--
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#030303"
                  >
                    <v-btn
                      @click="verIntegrante(integrante.nombre, integrante.id)"
                    >

                    See more info
                    
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
                -->
                <v-chip-group column>
                  <v-chip
                    v-for="(tag,i) in integrante.links"
                    :key="i"
                    class="mx-auto white--text"
                    color="#344898"
                  >
                    {{tag.tag}}
                  </v-chip>
                </v-chip-group>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

    </div>

    <!-- GRAD -->
    <div>

      <v-card  class="mx-auto mt-10 info-card3" color="transparent" width="90%" dark elevation="24">
      
        <!--<v-img class="white--text" height="50" src="../assets/fondo9.svg">-->

          <h1 class="contenido-team"> GRAD STUDENTS </h1>

        <!--</v-img>-->
      </v-card>

      <v-row class="mt-16 mb-16">

        <v-col
          align-self="center"
          v-for="(integrante,i) in gradStudents"
          :key="i"
          cols="12"
          sm="4"
        >
          <v-hover>
            <template>
              <v-card
                elevation="24"
                class="mx-auto info-card4"
                max-width="300"
                color="#f6f4f5"
              >
                <v-avatar
                  class="profile"
                  size="300"
                  rounded
                >
                  <v-img 
                    :src="integrante.img"
                    transition="fade-transition"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <v-card-text>
                  <h2 class="text-h6 primary--text">
                  {{integrante.nombre}}
                  </h2>
                  <h3>
                  {{integrante.grado}}
                  </h3>
                  {{integrante.cargo}}

                  <h4>
                  {{integrante.email}}
                  </h4>

                </v-card-text>
                <!--
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#030303"
                  >
                    <v-btn
                      @click="verIntegrante(integrante.nombre)"
                    >

                    See more info
                    
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
                -->
                <v-chip-group column>
                  <v-chip
                    v-for="(tag,i) in integrante.links"
                    :key="i"
                    dark
                    class="mx-auto white--text"
                    color="#344898"
                  >
                    {{tag.tag}}
                  </v-chip>
                </v-chip-group>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

    </div>

    <!-- UNDERGRAD -->
    <div>

      <v-card  class="mx-auto mt-10 info-card5" color="transparent" width="90%" dark elevation="24">
      
        <!--<v-img class="white--text" height="50" src="../assets/fondo9.svg">-->

          <h1 class="contenido-team"> UNDERGRAD STUDENTS </h1>

        <!--</v-img>-->
      </v-card>

      <v-row class="mt-16 mb-16">

        <v-col
          align-self="center"
          v-for="(integrante,i) in undergradStudents"
          :key="i"
          cols="12"
          sm="4"
        >
          <v-hover>
            <template>
              <v-card
                elevation="24"
                class="mx-auto info-card6"
                max-width="300"
                color="#f6f4f5"
              >
                <v-avatar
                  class="profile"
                  size="300"
                  rounded
                >
                  <v-img 
                    :src="integrante.img"
                    transition="fade-transition"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <v-card-text>
                  <h2 class="text-h6 primary--text">
                  {{integrante.nombre}}
                  </h2>
                  {{integrante.trabajo}}
                  <h4>
                  {{integrante.email}}
                  </h4>

                </v-card-text>

                <!--
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#030303"
                  >
                    <v-btn
                      @click="verIntegrante(integrante.nombre)"
                    >

                    See more info
                    
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
                -->
                <v-chip-group column>
                  <v-chip
                    v-for="(tag,i) in integrante.links"
                    :key="i"
                    dark
                    class="mx-auto white--text"
                    color="#344898"
                  >
                    {{tag.tag}}
                  </v-chip>
                </v-chip-group>

              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

    </div>

    <!-- PAST -->
    <div>

      <v-card  class="mx-auto mt-10 info-card7" color="transparent" width="90%" dark elevation="24">
      
        <!--<v-img class="white--text" height="50" src="../assets/fondo9.svg">-->

          <h1 class="contenido-team"> PAST STUDENTS </h1>

        <!--</v-img>-->
      </v-card>

      <v-row class="mt-16">

        <v-col
          align-self="center"
          v-for="(integrante,i) in pastStudents"
          :key="i"
          cols="12"
          sm="4"
        >
          <v-hover>
            <template>
              <v-card
                elevation="24"
                class="mx-auto info-card8"
                max-width="300"
                color="#f6f4f5"
              >
                <v-avatar
                  class="profile"
                  size="300"
                  rounded
                >
                  <v-img 
                    :src="integrante.img"
                    transition="fade-transition"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <v-card-text>
                  <h2 class="text-h6 primary--text">
                  {{integrante.nombre}}
                  </h2>

                  <h4>
                  {{integrante.email}}
                  </h4>
                </v-card-text>
                <!--
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#030303"
                  >
                    <v-btn
                      @click="verIntegrante(integrante.nombre)"
                    >

                    See more info
                    
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
                -->
                <v-chip-group column>
                  <v-chip
                    v-for="(tag,i) in integrante.links"
                    :key="i"
                    dark
                    class="mx-auto white--text"
                    color="#344898"
                  >
                    {{tag.tag}}
                  </v-chip>
                </v-chip-group>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

    </div>

  </div>
</template>

<script>
  export default{
    name: 'Team',
    data () {
      return {
        faculty:[
          {
            nombre: 'Gonzalo Acuña',
            email: 'gonzalo.acuna@usach.cl',
            grado:'PhD en Control Automático',
            cargo: 'Full Professor',
            img: require('../assets/Team/gonzalo_acuna.jpg'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Violeta Chang',
            email: 'violeta.chang@usach.cl',
            grado: 'PhD Computer Science',
            cargo: 'Assistant Professor',
            img: require('../assets/Team/violeta_chang.jpg'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Leonel Medina',
            email: 'leonel.medina@usach.cl',
            grado: 'PhD Biomedical Engineering',
            cargo: 'Assistant Professor',
            img: require('../assets/Team/leonel_medina.jpg'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          
        ],

        gradStudents:[
          {
            nombre: 'Patricia Melo',
            email: 'patricia.melo@usach.cl',
            grado: 'MSc student',
            img: require('../assets/Team/female1.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Michael Castillo',
            email: 'michael.castillo@usach.cl',
            grado: 'MSc student',
            img: require('../assets/Team/male2.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
        ],

        undergradStudents:[
          {
            nombre: 'Benjamín Morales',
            email: 'benjamin.morales@usach.cl',
            trabajo: 'Automatic grading of breast cancer biopsies using deep learning',
            img: require('../assets/Team/BenjaminMorales.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Felipe Gonzáles',
            email: 'felipe.gonzales@usach.cl',
            trabajo: 'Impact of non-local features in human sperm segmentation',
            img: require('../assets/Team/FelipeGonzalez.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Benjamín Hernández',
            email: 'benjamin.hernandez@usach.cl',
            trabajo: 'Generation of gold-stardard of breast cancer biopsies',
            img: require('../assets/Team/male1.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Pascale Cuevas',
            email: 'pascale.cuevas@usach.cl',
            trabajo: 'Detection of underwater debris using deep learning algorithms',
            img: require('../assets/Team/PascaleCuevas.jpg'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Luis Abello',
            email: 'luis.abello@usach.cl',
            trabajo: 'Optimization of U-net architecture for human sperm segmentation',
            img: require('../assets/Team/LuisAbello.jpg'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
        ],

        pastStudents:[
          {
            nombre: 'Javier Velásquez',
            email: 'javie.velasquez@usach.cl',
            img: require('../assets/Team/male1.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Nicolás Román',
            email: 'nicolas.roman@usach.cl',
            img: require('../assets/Team/male2.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
          {
            nombre: 'Ruth Marin',
            email: 'ruth.marin@usach.cl',
            img: require('../assets/Team/female1.png'),
            links: [
              {
                link: 'researchgate.com',
                tag: 'ResearchGate'
              },
              {
                link: 'linkedin.com',
                tag: 'LinkedIn'
              },
              {
                link:'Email.com',
                tag:'Email'
              }
            ]
          },
        ],
      }
    },

    methods:{
      randomColor(){
        return this.colors[Math.floor(Math.random() * 5)]
      },
      verIntegrante(nombre){
        this.$router.push("/Team/" + nombre.replace(/\s+/g, '_'))
      },
      copiarPortapapeles(){
        navigator.clipboard.writeText();
        return 0;
      }
    }

  }

</script>

<style scoped>

  .space{
    margin-bottom:132px;
  }
  .contenido-team{
    animation: name2 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s forwards;
    opacity: 0;
  }
  @keyframes name2 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }


  .info-card{
      width: 90%;
      opacity: 0;
      animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    } 
  .info-card2{
      width: 300px;
      opacity: 0;
      animation: slide-top2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top2 {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

  .info-card3{
      width: 90%;
      opacity: 0;
      animation: slide-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    } 
  .info-card4{
      width: 300px;
      opacity: 0;
      animation: slide-top2 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top2 {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  .info-card5{
      width: 90%;
      opacity: 0;
      animation: slide-top 1.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    } 
  .info-card6{
      width: 300px;
      opacity: 0;
      animation: slide-top2 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top2 {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  .info-card7{
      width: 90%;
      opacity: 0;
      animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    } 
  .info-card8{
      width: 300px;
      opacity: 0;
      animation: slide-top2 1.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top2 {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
</style>